import { VStack, Tag, Wrap } from "@chakra-ui/react";
import { FC } from "react";
import { Profile } from "types/profile.types";

const Expertise: FC<{ defaultProfile: Profile | undefined }> = ({
  defaultProfile,
}) => (
  <VStack align="start">
    <Wrap spacing={2}>
      {defaultProfile &&
        defaultProfile.expertise.map((label) => (
          <Tag
            size="md"
            key={label.name}
            variant="solid"
            bg="blue.50"
            color="blue.500"
            fontWeight="medium"
            px={2}
            noOfLines={1}
            alignItems="center"
            display="flex"
          >
            {label.name}
          </Tag>
        ))}
    </Wrap>
  </VStack>
);

export default Expertise;

import { FC } from "react";

import { Button, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "hooks/useCustomToast";
import { onUpdateOrderStatus } from "services/orderService";
import { orderKeys } from "settings/constants/queryKeys";
import ConfirmationModal from "components/ui/Modals/ConfirmationModal";

interface Props {
  orderId: string;
}
const CancelOrderButton: FC<Props> = ({ orderId }) => {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useCustomToast();
  const confirmationModalDisclosure = useDisclosure();

  const orderCancelMutation = useMutation({
    mutationFn: () => onUpdateOrderStatus(orderId, "cancelled"),
    onSuccess() {
      successToast({
        title: "Order has been cancelled successfully!",
        description: "Redirecting to orders page...",
      });
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) });
    },
    onError() {
      errorToast({
        title: "Failed to cancel the order.",
        description:
          "Something went wrong, please try again or contact support.",
      });
    },
  });

  return (
    <>
      <Button
        colorScheme="red"
        w="full"
        size="md"
        variant="solid"
        isLoading={orderCancelMutation.isPending}
        loadingText="Canceling order..."
        onClick={confirmationModalDisclosure.onOpen}
      >
        Cancel
      </Button>

      <ConfirmationModal
        title="Are you sure?"
        description="Once confirmed, the order will be permanently deleted and cannot be recovered."
        confirmButtonColorScheme="red"
        onConfirm={() => orderCancelMutation.mutate()}
        isOpen={confirmationModalDisclosure.isOpen}
        onClose={confirmationModalDisclosure.onClose}
        onOpen={confirmationModalDisclosure.onOpen}
      />
    </>
  );
};

export default CancelOrderButton;

import { FC } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FcCancel } from "react-icons/fc";
import CancelOrderButton from "./CancelOrderButton";
import { Order } from "types/order.types";

interface Props {
  order: Order;
}
const OrderCancelSection: FC<Props> = ({ order }) => {
  return (
    <Card variant="outline" w="full" userSelect="none" borderColor="red.200">
      <CardHeader>
        <Stack spacing={4}>
          <VStack justify="space-between" align="start">
            <HStack>
              <Icon as={FcCancel} fontSize="2xl" />
              <Text fontWeight="semibold">Cancel Order</Text>
            </HStack>
            <Text>Cancel the order if you're unable to proceed.</Text>
          </VStack>
        </Stack>
      </CardHeader>
      <CardBody p={0}>
        <Stack
          bg="red.200"
          w="full"
          p={4}
          roundedBottomRight="sm"
          roundedBottomLeft="sm"
        >
          <CancelOrderButton orderId={order.id} />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default OrderCancelSection;

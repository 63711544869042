import { FC, useState } from "react";
import { Center, Icon } from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";
import { Attachment } from "types/attachments.types";
import ImageWithLoader from "pages/OfferPage/Image/ImageWithLoader";

interface Props {
  attachments: Attachment[];
}
const OfferCardImages: FC<Props> = ({ attachments }) => {
  const [isError, setIsError] = useState(false);
  const featuredImage =
    attachments.find((a: Attachment) => a.featured) || attachments[0];

  if (!featuredImage || isError) return <PlaceholderImage />;
  return (
    <Center h="full" position="relative">
      <ImageWithLoader
        style={{ objectFit: "cover" }}
        src={featuredImage.thumbnailUrl}
        alt={featuredImage.name}
        onError={() => setIsError(true)}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      />
    </Center>
  );
};

const PlaceholderImage = () => {
  return (
    <Center bg="gray.200" w="full" h="full">
      <Icon fontSize="4xl" color="gray.500" as={MdImageNotSupported} />
    </Center>
  );
};

export default OfferCardImages;

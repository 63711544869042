import axios from "libraries/axios";
import { API_URL } from "settings/site-credentials";
import { Offer } from "types/offer.types";
import { Profile, Review } from "types/profile.types";

export async function getAllProfiles(
  searchText: string = "",
  categoryId: string = ""
): Promise<Profile[]> {
  let url = `${API_URL}/profiles`;
  const params = new URLSearchParams();
  if (searchText) {
    params.append("search", encodeURIComponent(searchText));
  }

  if (categoryId) {
    params.append("category", categoryId);
  }

  const queryString = params.toString();
  if (queryString) {
    url = `${url}?${queryString}`;
  }

  const { data } = await axios.get(url);

  return data.data.profiles;
}

export type ProfileByIdResponse = { success: boolean; profile: Profile | null };
export async function getProfileById(id: string): Promise<ProfileByIdResponse> {
  try {
    const { data } = await axios.get(`/profiles/${id}`);

    return { success: true, profile: data.data.profile };
  } catch (error) {
    return { success: false, profile: null };
  }
}

//Get offers by profileId
export async function getOfferByProfileId(id: string): Promise<Offer[]> {
  const { data } = await axios.get(`/profiles/${id}/offers`);
  return data.data.offers;
}

export async function getProfileRatingById(id: string): Promise<Review> {
  const { data } = await axios.get(`/profiles/${id}/reviews`);
  return data.data.reviews;
}

import { Skeleton, VStack } from "@chakra-ui/react";
import useOrderReceipt from "hooks/useOrderReceipt";
import ReceiptExpirationAlert from "./ReceiptExpirationAlert";

const OrderReceipt = ({ orderId }: { orderId: string }) => {
  const { data, isLoading } = useOrderReceipt(orderId);

  if (!!isLoading) return <Skeleton w="full" h="22px" />;
  return (
    <VStack w="full">
      <ReceiptExpirationAlert data={data} />
    </VStack>
  );
};

export default OrderReceipt;

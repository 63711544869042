import React, { ForwardedRef, useEffect } from "react";
import {
  Text,
  CenterProps,
  Stack,
  Divider,
  HStack,
  IconButton,
  Icon,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Badge,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import RenderLink from "./RenderLink/index";
import parse from "html-react-parser";
import { UseMutateFunction } from "@tanstack/react-query";
import { IoNotificationsOffOutline } from "react-icons/io5";
import { NotificationTag } from "./NotificationTag";
import { NotificationIcon } from "./NotificationIcon";
import { NotificationHR, ReadReceipt } from "types/notification.type";
import {
  formatDateRelative,
  groupNotificationsByDate,
} from "utils/notificationGroup";
import Alert from "components/features/Alert";

interface NotificationBodyProps extends CenterProps {
  data: NotificationHR[];
  onClickNotification?: ({
    notificationId,
    isRead,
  }: {
    notificationId: string;
    isRead: boolean;
  }) => Promise<void>;
  onDeleteNotification: UseMutateFunction<unknown, Error, string[], unknown>;
  userId: string;
}

function checkIfRead(userIds: ReadReceipt[], userId: string) {
  return userIds.some((user) => user.userId === userId);
}

export const NotificationBody = React.forwardRef(
  (props: NotificationBodyProps, ref: ForwardedRef<HTMLDivElement>) => {
    const filterNotificationsByActions = (
      notifications: NotificationHR[],
      actions: string[]
    ) => {
      return notifications.filter((notification) =>
        actions.includes(notification.action)
      );
    };

    // Usage of the utility function
    const chatNotifications = filterNotificationsByActions(props.data, [
      "message_created",
    ]);

    const orderNotifications = filterNotificationsByActions(props.data, [
      "order_placed",
      "order_completed",
      "order_started",
      "rating_created",
      "order_cancelled",
    ]);

    const systemNotifications = filterNotificationsByActions(props.data, [
      // Assuming system notifications are those not related to chat or order actions
      ...props.data
        .map((notification) => notification.action)
        .filter(
          (action) =>
            ![
              "message_created",
              "order_placed",
              "order_completed",
              "order_started",
              "rating_created",
              "order_cancelled",
            ].includes(action)
        ),
    ]);

    const getUnreadCount = (notifications: NotificationHR[]) => {
      return notifications.filter(
        (notification) => !checkIfRead(notification.readBy, props.userId)
      ).length;
    };

    const chatUnreadCount = getUnreadCount(chatNotifications);
    const orderUnreadCount = getUnreadCount(orderNotifications);
    const systemUnreadCount = getUnreadCount(systemNotifications);

    const renderNotifications = (notifications: NotificationHR[]) => {
      const groupedNotifications = groupNotificationsByDate(
        notifications || []
      );
      const renderGroup = (title: string, notifications: NotificationHR[]) => (
        <Box key={title}>
          <Text
            fontSize="10px"
            textTransform="uppercase"
            p={1}
            background="blue.50"
            fontWeight="bold"
            color="blue.500"
          >
            {title}
          </Text>
          <Stack>
            {notifications.map((notification) => (
              <Box key={notification._id}>
                <HStack
                  w="full"
                  spacing={0}
                  align="center"
                  px={{ base: 2, md: 4 }}
                >
                  <NotificationIcon referenceType={notification.action} />
                  <NotificationCard
                    userId={props.userId}
                    key={notification.id}
                    data={notification}
                    onClickNotification={props.onClickNotification}
                    onDeleteNotification={props.onDeleteNotification}
                  />
                </HStack>
                <Divider />
              </Box>
            ))}
          </Stack>
        </Box>
      );

      return (
        <Stack w="full" spacing={0} ref={ref}>
          {notifications.length > 0 ? (
            <Box pb={14} w="full">
              {groupedNotifications.today.length > 0 &&
                renderGroup("Today", groupedNotifications.today)}
              {groupedNotifications.yesterday.length > 0 &&
                renderGroup("Yesterday", groupedNotifications.yesterday)}
              {groupedNotifications.earlier.length > 0 &&
                renderGroup("Earlier", groupedNotifications.earlier)}
            </Box>
          ) : (
            <Stack
              top="0"
              left="0"
              position="absolute"
              w="full"
              h="full"
              alignItems="center"
              justifyContent="center"
              pointerEvents="none"
            >
              <Icon
                as={IoNotificationsOffOutline}
                fontSize="4xl"
                color="gray"
              />
              <Text color="gray.500" fontSize="sm">
                Nothing to show
              </Text>
            </Stack>
          )}
        </Stack>
      );
    };

    return (
      <Tabs h="full" position="relative">
        <TabList>
          <Tab>
            <Flex gap={2} alignItems="center">
              Chats
              {chatUnreadCount > 0 && (
                <Badge colorScheme="red" rounded="full">
                  {chatUnreadCount}
                </Badge>
              )}
            </Flex>
          </Tab>
          <Tab>
            <Flex gap={2} alignItems="center">
              Orders
              {orderUnreadCount > 0 && (
                <Badge colorScheme="red" rounded="full">
                  {orderUnreadCount}
                </Badge>
              )}
            </Flex>
          </Tab>
          <Tab>
            <Flex gap={2} alignItems="center">
              System
              {systemUnreadCount > 0 && (
                <Badge colorScheme="red" rounded="full">
                  {systemUnreadCount}
                </Badge>
              )}
            </Flex>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>{renderNotifications(chatNotifications)}</TabPanel>
          <TabPanel p={0}>{renderNotifications(orderNotifications)}</TabPanel>
          <TabPanel p={0}>{renderNotifications(systemNotifications)}</TabPanel>
        </TabPanels>
      </Tabs>
    );
  }
);

interface NotificationCardProps {
  data: NotificationHR;
  onClickNotification?: ({
    notificationId,
    isRead,
  }: {
    notificationId: string;
    isRead: boolean;
  }) => Promise<void>;
  onDeleteNotification: UseMutateFunction<unknown, Error, string[], unknown>;
  userId: string;
}

export function NotificationCard({
  data,
  onClickNotification,
  onDeleteNotification,
  userId,
}: NotificationCardProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  function checkIfRead(userIds: ReadReceipt[], userId: string) {
    return userIds.some((user) => user.userId === userId);
  }

  function getMessage(data: NotificationHR): string {
    if (data.message) return data.message;
    return data.action === "message_created"
      ? "Click here to preview"
      : "Notification";
  }

  if (!data)
    return (
      <Alert status="error" description="Failed to fetch notifications." />
    );
  return (
    <Stack position="relative" w="full">
      <Stack
        onClick={() =>
          onClickNotification &&
          onClickNotification({
            notificationId: data.id,
            isRead: checkIfRead(data.readBy, userId),
          })
        }
        as={RenderLink}
        data={data}
        borderLeft={
          !checkIfRead(data.readBy, userId) ? "4px solid #2b6cb0" : ""
        }
        w="full"
        px="2"
        py="0"
      >
        <Text
          fontSize={{ base: "12", md: "15" }}
          color="gray.600"
          fontWeight="semibold"
          textTransform="capitalize"
          py={0}
          noOfLines={1}
        >
          {parse(getMessage(data))}
        </Text>

        <HStack justify="start" alignItems="center" gap={2}>
          <NotificationTag key={data.id} priority={data.action}>
            {data.priority}
          </NotificationTag>
          <Text
            fontSize={{ base: "11", md: "12" }}
            color="GrayText"
            fontWeight="semibold"
          >
            {formatDateRelative(new Date(data.updatedAt))}
          </Text>
        </HStack>
      </Stack>

      <IconButton
        size="lg"
        variant="link"
        colorScheme="red"
        position="absolute"
        right={-4}
        bottom={5}
        aria-label="delete-notification"
        icon={<MdDelete />}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await onDeleteNotification([data.id]);
          setIsLoading(false);
        }}
      />
    </Stack>
  );
}

import { FC, useState } from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  SimpleGrid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInputField from "components/ui/common/TextInputField";
import TextareaField from "components/ui/common/TextareaField";
import useOrderDeliveries from "hooks/useOrderDeliveries";
import CheckIcon from "icons/CheckIcon";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRevision } from "services/revisionService";
import * as yup from "yup";
import { orderKeys } from "settings/constants/queryKeys";
import useCustomToast from "hooks/useCustomToast";
import { Dropzone, FilePreview, ImageEditor } from "hr-design-system";
import useGalleryForm from "hooks/useGalleryForm";
import { BiEdit, BiPlus } from "react-icons/bi";
import PlusIcon from "icons/PlusIcon";
import useQueryParams from "hooks/useQueryParams";
import ActivityAttachments from "pages/OrderPage/ActivitiesSection/DeliveryActivity/ActivityAttachments";

const REVISION_DESCRIPTION_LENGTH = 500;
const REVISION_TITLE_LENGTH = 100;

const orderDeliverySchema = yup.object({
  title: yup.string().required().label("Title"),
  deliveryId: yup.string().required().label("Delivery"),
  description: yup
    .string()
    .min(10)
    .max(REVISION_DESCRIPTION_LENGTH)
    .required()
    .label("Description"),
});
interface FormProps {
  title: string;
  description: string;
  deliveryId: string;
}
interface Props {
  orderId: string;
}
const RevisionForm: FC<Props> = ({ orderId }) => {
  const queryClient = useQueryClient();
  const { data: orderDeliveries } = useOrderDeliveries(orderId);
  const { errorToast } = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    uploadedFiles,
    prevUploadedFiles,
    dropzoneDisclosure,
    handleFileUpload,
    handleSave: handleUploadAttachment,
    handleRemoveAttachment,
    isUploading,
  } = useGalleryForm({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormProps>({
    resolver: yupResolver(orderDeliverySchema),
  });
  const [editFile, setEditFile] = useState<{
    cdnUrl?: string;
    name?: string;
  } | null>(null);

  const { removeParam } = useQueryParams();
  const createRevisionMutation = useMutation({
    mutationFn: async (data: FormProps) => {
      const { finalAttachments } = await handleUploadAttachment();
      return createRevision({
        orderId,
        deliveryId: data.deliveryId,
        title: data.title,
        customerRequest: {
          details: data.description,
          attachments: finalAttachments,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) });
      removeParam("action");
    },
    onError: () => {
      errorToast({
        title: "Failed to mark order as complete.",
        description:
          "Something went wrong, please try again or contact support",
      });
    },
  });

  const onCancelRevision = () => {
    removeParam("action");
  };

  const latestDelivery = orderDeliveries
    ? orderDeliveries[orderDeliveries.length - 1]
    : null;

  return (
    <Stack
      spacing={4}
      as="form"
      onSubmit={handleSubmit((data) => createRevisionMutation.mutate(data))}
    >
      <Stack spacing={0}>
        <TextInputField
          {...register("title")}
          label="Title"
          errorMessage={errors.title?.message}
          maxLength={REVISION_TITLE_LENGTH}
          currLength={watch("title")?.length}
        />
        <TextareaField
          {...register("description")}
          label="Description"
          errorMessage={errors.description?.message}
          maxLength={REVISION_DESCRIPTION_LENGTH}
          currLength={watch("description")?.length}
          minH={{ base: "15rem", md: "8rem" }}
        />
        {latestDelivery && (
          <Stack alignItems="start" spacing={0} w="full" h="full" mb={4}>
            <FormLabel>Last delivery</FormLabel>
            <ActivityAttachments
              attachmentIds={latestDelivery.attachments}
              onOpen={onOpen}
              setEditFile={setEditFile}
            />
          </Stack>
        )}
        <Stack alignItems="start" spacing={0} w="full" h="full">
          <FormLabel>Attachments</FormLabel>
          <SimpleGrid
            columns={{ base: 2, sm: 3, lg: 4 }}
            spacing={2}
            w="full"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Menu>
                <MenuButton
                  aria-label="upload"
                  rounded="full"
                  type="button"
                  w="full"
                >
                  <Center
                    gap={2}
                    w="full"
                    height="32"
                    rounded="md"
                    p={2}
                    border="1px dashed #DADBDD"
                    justifyContent="center"
                    onClick={dropzoneDisclosure.onOpen}
                  >
                    <IconButton
                      as={PlusIcon}
                      aria-label="Upload media"
                      colorScheme="blue"
                      rounded="full"
                      size="sm"
                      type="button"
                    />
                  </Center>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<BiPlus size={20} />}
                    onClick={() => {
                      dropzoneDisclosure.onOpen();
                    }}
                  >
                    Upload attachment
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      onOpen();
                      setEditFile(null); // Open ImageEditor with no image
                    }}
                    icon={<BiEdit size={20} />}
                  >
                    Upload and Edit
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            {[...prevUploadedFiles, ...uploadedFiles].map((file) => (
              <FilePreview
                key={file.id}
                styles={{
                  width: "full",
                  height: "32",
                }}
                fileName={file.name}
                allowClickToEnlarge={true}
                fileURL={file.url}
                fileType={file.format}
                allowRemove={true}
                allowDownload={false}
                onRemove={() => handleRemoveAttachment(file.id)}
              />
            ))}
          </SimpleGrid>
        </Stack>
        <FormControl mt={4}>
          <FormLabel>Delivery</FormLabel>
          <Select {...register("deliveryId")}>
            {orderDeliveries &&
              orderDeliveries.map((delivery) => (
                <option key={delivery._id} value={delivery._id}>
                  {delivery.title}
                </option>
              ))}
          </Select>
        </FormControl>
      </Stack>

      <HStack w="max" alignSelf="flex-end">
        <Button variant="ghost" onClick={onCancelRevision}>
          Cancel
        </Button>
        <Button
          colorScheme="red"
          rightIcon={<CheckIcon />}
          isLoading={isUploading || createRevisionMutation.isPending}
          type="submit"
        >
          Submit revision
        </Button>
      </HStack>

      {isOpen && (
        <ImageEditor
          defaultUploadFile={
            editFile
              ? {
                  path: editFile.cdnUrl || "",
                  name: editFile.name || "",
                }
              : { path: "", name: "" } // Default state with no image
          }
          wrapInModal={true}
          onUpload={async (file) => {
            const convertedFile = new File([file.blobFile], file.fileName, {
              type: file.blobFile.type,
            });
            handleFileUpload(convertedFile);
            onClose();
          }}
          onModalClose={() => {
            onClose();
          }}
        />
      )}

      {dropzoneDisclosure.isOpen && (
        <Dropzone
          acceptOnlyImages={false}
          wrapInModal={true}
          allowMultiple
          onUpload={() => {}}
          onClose={dropzoneDisclosure.onClose}
          onFileUpload={handleFileUpload}
        />
      )}
    </Stack>
  );
};

export default RevisionForm;

import { FC, ReactNode, useEffect } from "react";
import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import OfferCard from "components/sections/GigsSections/OfferCard";
import Alert from "components/features/Alert";
import useOffers from "hooks/useOffers";

interface Props {
  search: string;
}
const RenderOffers: FC<Props> = ({ search }) => {
  const { data: offers, isLoading, error, onSearch } = useOffers();

  useEffect(() => {
    onSearch(search);
  }, [search]);

  if (!!isLoading) return <OffersSkeletonLoading />;
  if (!!error || !offers)
    return (
      <Alert
        w="max"
        status="error"
        title="Failed to fetch offers"
        description="Please try reload or contact support if error persists."
      />
    );
  if (!offers)
    return (
      <Alert
        w="max"
        status="error"
        title="Unable to fetch offers"
        description="Please try reload or contact support if error persists."
      />
    );
  if (offers.length === 0)
    return (
      <Alert status="info" title="No offers have been created yet." w="max" />
    );
  return (
    <Layout>
      {offers.map((offer) => {
        return <OfferCard key={offer._id} id={offer._id} offer={offer} />;
      })}
    </Layout>
  );
};

const OffersSkeletonLoading = () => {
  return (
    <Layout>
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SimpleGrid w="full" gap="6" columns={{ base: 1, md: 3 }} px={2}>
      {children}
    </SimpleGrid>
  );
};

export default RenderOffers;

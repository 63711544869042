import axios from "libraries/axios";
import { Language } from "types/language.types";
import { Order, OrderDelivery, OrderStatus } from "types/order.types";

export async function getAllOrders({
  userId,
  status,
}: {
  userId: string;
  status?: OrderStatus;
}) {
  let url = `/users/${userId}/orders`;
  if (!!status) {
    url = `${url}?status=${status}`;
  }
  const response = await axios.get(url);

  return response.data.data.orders;
}

export async function getOrderById(id: string): Promise<Order> {
  const response = await axios.get(`/orders/${id}`);

  return response.data.data.order;
}

export const getLanguageById = async (id: string): Promise<Language> => {
  const response = await axios.get(`/languages/${id}`);
  return response.data.data.language;
};

export async function getOrderDeliveries(id: string): Promise<OrderDelivery[]> {
  const response = await axios.get(`/orders/${id}/deliveries`);
  return response.data.data.deliveries;
}

export async function onUpdateOrderStatus(id: string, status: OrderStatus) {
  const response = await axios.put(`/orders/${id}/status`, { status });
  return response.data.data.order;
}

export async function createOrderDelivery(
  data: Partial<OrderDelivery>
): Promise<OrderDelivery> {
  const response = await axios.post(`/deliveries`, data);
  return response.data.data.delivery;
}

import {
  VStack,
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  Stack,
  ModalOverlay,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Flex,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import ImageWithLoader from "pages/OfferPage/Image/ImageWithLoader";
import { FC, useState } from "react";
import { RiGalleryFill } from "react-icons/ri";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Attachment } from "types/attachments.types";

const GallerySection: FC<{ gallery: Attachment[] }> = ({ gallery }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    onOpen();
  };

  return (
    <Stack spacing={5} py={5}>
      {gallery.length > 0 ? (
        <Box>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 2 }}
          >
            <Masonry gutter="20px">
              {gallery.map((item) => (
                <Box
                  key={item.cdnUrl}
                  borderRadius="lg"
                  overflow="hidden"
                  pos="relative"
                  border="1px"
                  borderColor="gray.200"
                  cursor="pointer"
                  onClick={() => handleImageClick(item.cdnUrl)}
                  _hover={{
                    ".overlay": { opacity: 1 },
                  }}
                >
                  <ImageWithLoader
                    src={item.cdnUrl}
                    alt={item.name}
                    width="100%"
                    borderRadius="lg"
                  />
                  <Flex
                    className="overlay"
                    pos="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    bgGradient="linear(to-t, blackAlpha.800, transparent)"
                    color="white"
                    p={3}
                    h="full"
                    opacity={0}
                    transition="opacity 0.3s ease"
                    alignItems="end"
                  >
                    <Text fontSize="md" fontWeight="bold" color="white">
                      {item.name}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Masonry>
          </ResponsiveMasonry>

          {/* Modal for full-size image */}
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent bg="blackAlpha.900">
              <ModalCloseButton bg="blue.500" color="white" />
              <ModalBody
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {selectedImage && (
                  <ImageWithLoader
                    alt="gallery"
                    src={selectedImage}
                    maxW="100%"
                    maxH="100%"
                  />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      ) : (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          bg="gray.200"
          rounded="md"
        >
          <VStack>
            <RiGalleryFill fontSize="40px" />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              No gallery found
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              There is no gallery in this profile
            </AlertDescription>
          </VStack>
        </Alert>
      )}
    </Stack>
  );
};

export default GallerySection;

import {
  Avatar,
  Box,
  HStack,
  Stack,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Logo } from "components/ui/logo/Logo2";
import { format } from "date-fns";
import { FC } from "react";
import { Profile } from "types/profile.types";
import ProfileRatings from "./ProfileRatings";

interface HeaderSection {
  data: Profile | undefined;
}

const HeaderSection: FC<HeaderSection> = ({ data }) => {
  return (
    <VStack rounded="md" w="full">
      <Flex
        w="full"
        alignItems="center"
        justifyContent="end"
        h="70px"
        bgGradient="linear(to-l, blue.200 0%, blue.100 50%, white 100%)"
        rounded="md"
      >
        <Logo h="35px" color="white" />
      </Flex>
      <Stack w="full">
        <HStack
          spacing={{ base: 10, md: 20 }}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "start", md: "center" }}
        >
          <HStack spacing={2} flexDirection="row">
            <HStack alignItems="start" spacing={4}>
              <Box pos="relative" mt={-6}>
                <Avatar size="2xl" src={data?.avatar || ""} bg="blue.400" />
                <Box
                  bg="green.400"
                  boxSize={4}
                  rounded="full"
                  position="absolute"
                  right={2}
                  bottom={2}
                  border="2px solid"
                  borderColor="white"
                />
              </Box>
              <VStack flexDirection="column" align="start" gap="1">
                <Text fontSize="lg" fontWeight="bold" color="black">
                  {data?.title}
                </Text>
                <ProfileRatings profile={data} />
                <HStack spacing={2}>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    fontWeight="semibold"
                  >
                    {data?.availability.assetsPerWeek} assets per week
                  </Text>
                  <Text>|</Text>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    fontWeight="semibold"
                  >
                    Joining date:{" "}
                    {format(new Date(data?.createdAt || ""), "dd MMM yyyy")}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          </HStack>
        </HStack>
      </Stack>
    </VStack>
  );
};

export default HeaderSection;

import {
  Container,
  Spinner,
  Stack,
  Flex,
  Alert,
  Divider,
} from "@chakra-ui/react";
import useProfile from "hooks/useProfile";
import HeaderSection from "pages/ProfilesPage/ProfileSection/HeaderSection";
import ProfileInfo from "pages/ProfilesPage/ProfileSection/ProfileInfo";
import ShowCase from "pages/ProfilesPage/ProfileSection/ShowCase";
import { useParams } from "react-router-dom";

const UserProfilePage = () => {
  const params = useParams();
  const { data, error, isLoading } = useProfile({
    profileId: params.profileId || "",
  });

  if (!!error)
    return (
      <Container centerContent>
        <Alert status="error" title={"Something went wrong"} />
      </Container>
    );
  if (!!isLoading)
    return (
      <Container centerContent>
        <Spinner />
      </Container>
    );
  if (!data?.profile)
    return (
      <Container centerContent>
        <Alert status="error" title={"Profile not found."} />
      </Container>
    );

  return (
    <Stack gap={10} position="relative">
      <Container maxW="container.xl" my={10} px={{ base: 5, md: 10 }}>
        <Stack spacing={{ md: 9, sm: 4 }} rounded="2xl" p={4}>
          <HeaderSection data={data?.profile} />

          <Divider />
          <Flex direction={{ base: "column", md: "row" }} w="full" gap={10}>
            <ProfileInfo data={data?.profile} />
            <ShowCase profile={data?.profile} />
          </Flex>
        </Stack>
      </Container>
    </Stack>
  );
};

export default UserProfilePage;

import { Icon } from "@chakra-ui/react";
import { FiMessageCircle, FiPackage } from "react-icons/fi";
import { IoNotificationsOutline } from "react-icons/io5";

interface NotificationIconProps {
  referenceType: string; // Assuming priority is a string, adjust as needed
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({
  referenceType,
}) => {
  function getIcon(priority: string) {
    switch (priority.toLowerCase()) {
      case "message_created":
        return {
          icon: FiMessageCircle,
          bgColor: "green.100",
          color: "green.500",
        };
      case "order_placed":
      case "order_cancelled":
      case "order_started":
        return { icon: FiPackage, bgColor: "blue.100", color: "blue.500" }; // Red for high priority
      default:
        return {
          icon: IoNotificationsOutline,
          bgColor: "red.100",
          color: "red.500",
        };
    }
  }
  const { icon, bgColor, color } = getIcon(referenceType);

  return (
    <Icon
      as={icon}
      bg={bgColor}
      textTransform="uppercase"
      rounded="full"
      boxSize="25px"
      p={1}
      fontWeight="bold"
      color={color}
    ></Icon>
  );
};

"use client";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Text,
  Flex,
  VStack,
  HStack,
  Icon,
  Link,
  Button,
  Stack,
} from "@chakra-ui/react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { FaDownload } from "react-icons/fa6";
import { MdReceipt } from "react-icons/md";
import { Payment } from "types/payments.types";

interface ReceiptExpirationAlertProps {
  data: Payment | undefined;
}

const TimeBox = ({ value, label }: { value: string; label: string }) => (
  <VStack spacing={0} align="center">
    <Text fontSize="2xl" fontWeight="bold">
      {value}
    </Text>
    <Text fontSize="xs">{label}</Text>
  </VStack>
);

const Separator = () => (
  <Text fontSize="2xl" fontWeight="bold" mx={1}>
    :
  </Text>
);

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    return (
      <Text>
        Your receipt has expired and is no longer available for download.
      </Text>
    );
  } else {
    return (
      <Flex alignItems="start" justifyContent="center">
        <TimeBox value={String(days).padStart(2, "0")} label="Days" />
        <Separator />
        <TimeBox value={String(hours).padStart(2, "0")} label="Hours" />
        <Separator />
        <TimeBox value={String(minutes).padStart(2, "0")} label="Minutes" />
        <Separator />
        <TimeBox value={String(seconds).padStart(2, "0")} label="Seconds" />
      </Flex>
    );
  }
};

export default function ReceiptExpirationAlert({
  data,
}: ReceiptExpirationAlertProps) {
  const expirationDate =
    (data?.receiptCreatedAt || 0) + 30 * 24 * 60 * 60 * 1000; // 24 hours after creation

  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      rounded="md"
      px={2}
      py={5}
    >
      <AlertIcon boxSize="25px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="md">
        Receipt Expiration Notice
      </AlertTitle>
      <AlertDescription maxWidth="full">
        <Stack gap={4} w="full">
          <Box w="full">
            <Text mb={2} fontSize="sm">
              Your receipt will expire in:
            </Text>
            <Countdown date={expirationDate} renderer={renderer} />
            <Text mt={2} fontSize="sm">
              Please download it before it expires.
            </Text>
          </Box>

          <HStack
            justify={"space-between"}
            align="center"
            w="full"
            p={2}
            px={4}
            bg="white"
            rounded="md"
          >
            <HStack>
              <Text>Receipt</Text>
              <Icon as={MdReceipt} color="green" />
            </HStack>
            <Link
              href={data?.receiptUrl || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack>
                <Button variant="link" rightIcon={<Icon as={FaDownload} />}>
                  Download
                </Button>
              </HStack>
            </Link>
          </HStack>
        </Stack>
      </AlertDescription>
    </Alert>
  );
}

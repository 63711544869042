import { useQuery } from "@tanstack/react-query";
import { getLanguageById } from "services/orderService";

const useLanguage = (id?: string) => {
  return useQuery({
    queryKey: ["user_languages", id],
    queryFn: () => getLanguageById(id!),
    enabled: !!id,
  });
};

export default useLanguage;

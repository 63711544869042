/////////////////////
// ProjectsLayout
/////////////////////

// Basic Imports
import { FC } from "react";
import { useLocation, Outlet } from "react-router-dom";

// Design Imports
import { Circle, HStack, Stack, Text, Container } from "@chakra-ui/react";

// Layout and Section Imports
import PageHeader from "../ui/appPages/PageHeader";
import { TabLink } from "../ui/appPages/TabLink";
import DashboardContainer from "../sections/DashboardSection/DashboardContainer";
// import ToggleUser from "components/ui/toggleuser";

// Interfaces
interface Props {
  showNavigationHeader?: boolean;
  title?: string;
  isProjectContainer?: boolean;
  navItems?: NavItemProps[];
  isFor?: string;
}

interface NavItemProps {
  label: string;
  href: string;
  count?: number;
}

// InnerLayout Function
const InnerLayout: FC<{ isFor: string }> = ({ isFor }) => {
  return isFor !== "general" ? (
    // Render with Container for "projects" or "orders"
    <Container
      maxW="container.xl"
      shadow="base"
      px={2}
      pt={4}
      rounded="md"
      my={2}
    >
      <Stack pb="4" spacing="4" overflow="auto">
        <Outlet />
      </Stack>
    </Container>
  ) : (
    // Render with Stack only for other cases (e.g., "general")
    <Stack pb="4" spacing="4">
      <Outlet />
    </Stack>
  );
};

const DashboardLayout: FC<Props> = ({
  showNavigationHeader = true,
  title,
  navItems,
  isProjectContainer,
  isFor = "general",
}) => {
  const { pathname } = useLocation();

  return (
    <>
      {showNavigationHeader && (
        <PageHeader title={title || ""} overflow="hidden">
          {navItems &&
            navItems.map((item: NavItemProps, idx: number) => {
              const currentItem = pathname === item.href;

              return (
                <TabLink
                  key={idx.toString()}
                  href={item.href}
                  aria-current={currentItem ? "page" : "false"}
                >
                  <HStack>
                    <Text>{item.label}</Text>
                    {!!item.count && (
                      <Circle
                        bg="blue.500"
                        color="white"
                        size="15px"
                        p="10px"
                        rounded="full"
                        fontSize="xs"
                      >
                        {item.count}
                      </Circle>
                    )}
                  </HStack>
                </TabLink>
              );
            })}
        </PageHeader>
      )}

      <DashboardContainer isProjectContainer={isProjectContainer}>
        {/* Use the InnerLayout for conditional rendering based on isFor */}
        <InnerLayout isFor={isFor} />
        {/* <ToggleUser /> */}
      </DashboardContainer>
    </>
  );
};

export default DashboardLayout;

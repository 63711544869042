import { Card, Divider, HStack, Icon, Text, VStack } from "@chakra-ui/react";

import { FC } from "react";
import { BiWorld } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import Expertise from "./Expertise";
import { Profile } from "types/profile.types";
import { CountryFlag } from "components/ui/CountryFlags";
import Language from "./Language";

interface ProfileInfo {
  data: Profile | undefined;
}
const ProfileInfo: FC<ProfileInfo> = ({ data }) => {
  console.log({ data });
  return (
    <Card
      variant="outline"
      overflow="hidden"
      colorScheme="red"
      rounded="lg"
      pos="relative"
      display="flex"
      flexDirection="column"
      height="100%"
      w={{ base: "full", md: "35%" }}
      p={5}
      position={{ base: "relative", md: "sticky" }}
      top="20px"
    >
      <VStack gap={6} w="full" align="start">
        <VStack gap={2} align="start">
          <Text fontWeight="bold">About</Text>
          <Text>{data?.description}</Text>
        </VStack>

        <HStack justifyContent="space-between" w="full">
          <HStack gap={2} align="center">
            <Icon as={FaLocationDot} color="blue.400" />
            <Text fontWeight="semibold" fontSize="sm" color="gray.800">
              Location
            </Text>
            <HStack gap={2}>
              <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                {data?.talentId?.address?.country}
              </Text>
              <CountryFlag
                countryCode={data?.talentId?.address?.country || ""}
                width={16}
                height={16}
              />
            </HStack>
          </HStack>
          <HStack gap={2} align="center">
            <Icon as={BiWorld} color="blue.400" />
            <Text fontWeight="semibold" fontSize="sm" color="gray.800">
              Timezone
            </Text>
            <HStack gap={2}>
              <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                {data?.talentId?.timezone}
              </Text>
            </HStack>
          </HStack>
        </HStack>
        <Divider />
        <VStack gap={2} align="start">
          <Text fontWeight="bold">Expertise</Text>
          {data && <Expertise defaultProfile={data} />}
        </VStack>
        <Divider />
        <VStack gap={2} align="start">
          <Text fontWeight="bold">Languages</Text>
          {data?.talentId?.languages.map((language) => (
            <Language id={language || ""} key={language} />
          ))}
        </VStack>
      </VStack>
    </Card>
  );
};

export default ProfileInfo;

import { Container, Spinner, Alert } from "@chakra-ui/react";
import { FC } from "react";
import OffersSection from "./OffersSection";
import useProfileOffers from "hooks/useProfileOffer";

const ProfileOffers: FC<{ profileId: string }> = ({ profileId }) => {
  const { data: offers, error, isLoading } = useProfileOffers({ profileId });

  if (!!error)
    return (
      <Container centerContent>
        <Alert status="error" title={"Something went wrong"} />
      </Container>
    );
  if (!!isLoading)
    return (
      <Container centerContent>
        <Spinner />
      </Container>
    );

  return <OffersSection offers={offers} columns={{ base: 1, md: 1, lg: 2 }} />;
};

export default ProfileOffers;

import { FC } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
} from "@chakra-ui/react";
import ProfileOffers from "./ProfileOffers"; // Adjust the import path as necessary
import GallerySection from "./GallerySection"; // Adjust the import path as necessary
import { Profile } from "types/profile.types";

interface ShowCase {
  profile: Profile | undefined;
}
const ShowCase: FC<ShowCase> = ({ profile }) => {
  return (
    <Stack w={{ base: "full", md: "65%" }}>
      <Tabs variant="line">
        <TabList>
          <Tab>My Offers</Tab>
          <Tab>My Gallery</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <ProfileOffers profileId={profile?.id || ""} />
          </TabPanel>
          <TabPanel p={0}>
            <GallerySection gallery={profile?.galleryId?.attachments || []} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default ShowCase;

import { Skeleton } from "@chakra-ui/react";
import Ratings from "components/ui/common/Ratings";
import useProfileRatings from "hooks/useProfileRatings";

import { FC } from "react";
import { Profile } from "types/profile.types";

type ProfileRatings = {
  profile: Profile | undefined;
};
const ProfileRatings: FC<ProfileRatings> = ({ profile }) => {
  const { data, isLoading, error } = useProfileRatings(profile?.id || "");
  if (isLoading) return <Skeleton w="10rem" h="1.4rem" />;

  if (!!error) return <Ratings rating={0} size="xs" />;

  return (
    <Ratings
      rating={Math.floor(data?.averageRating || 0)}
      showDigits
      size="xs"
    />
  );
};

export default ProfileRatings;

import { FC } from "react";
import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import useOrderRatings from "hooks/useOrderRatings";
import Alert from "components/features/Alert";
import Ratings from "components/ui/common/Ratings";

interface Props {
  orderId: string;
}
const BuyerReviewActivity: FC<Props> = ({ orderId }) => {
  const { data: orderRatings, isLoading } = useOrderRatings(orderId);
  const buyerRating = orderRatings?.find(
    (rating) => rating.createdBy.role === "customer"
  );

  if (!!isLoading) return <Skeleton w="full" h="40" />;
  if (!buyerRating)
    return (
      <Alert
        status="warning"
        title="Unable to find customer review! Contact support"
      />
    );
  return (
    <Card variant="outline" size="sm" overflow="hidden">
      <CardHeader bg="gray.100" py={2}>
        <Text fontWeight="bold">Your Review</Text>
      </CardHeader>
      <CardBody>
        <Stack align="start" spacing={4}>
          <HStack>
            <Avatar
              mt={1}
              src={buyerRating.createdBy.avatar}
              name={buyerRating.createdBy.firstName}
            />
            <Stack spacing={1}>
              <Text>
                {buyerRating.createdBy.firstName}{" "}
                {buyerRating.createdBy.lastName}
              </Text>
              <Ratings rating={buyerRating.average} showDigits size="xs" />
            </Stack>
          </HStack>
          <Stack w="full" px={2}>
            {buyerRating.ratingFactors.map((factor, index) => (
              <HStack key={index} justify="space-between">
                <Text>{factor.label}</Text>
                <Ratings rating={factor.value} showDigits size="xs" />
              </HStack>
            ))}
          </Stack>
          {buyerRating.text && (
            <Stack
              spacing={1}
              py={2}
              px={3}
              bg="gray.100"
              w="full"
              rounded="md"
              minH="5rem"
            >
              <Text>{buyerRating.text}</Text>
            </Stack>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default BuyerReviewActivity;

import * as Flags from "country-flag-icons/react/3x2";

// Define a type for the country codes
type CountryCode = keyof typeof Flags;

export const CountryFlag = ({
  countryCode,
  width,
  height,
}: {
  countryCode: string;
  width: number;
  height: number;
}) => {
  const FlagComponent = Flags[countryCode as CountryCode];
  return FlagComponent ? (
    <FlagComponent title={countryCode} width={width} height={height} />
  ) : null;
};

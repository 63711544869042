interface IReactQueryKeys {
  ALL_PROJECTS: string;
  DRAFT_PROJECTS: string;
  ACTIVE_PROJECTS: string;
  COMPLETED_PROJECTS: string;
  DELETED_PROJECTS: string;
  PAUSED_PROJECTS: string;
}
export const REACT_QUERY_KEYS: IReactQueryKeys = {
  ALL_PROJECTS: "allProjects",
  DRAFT_PROJECTS: "draftProjects",
  ACTIVE_PROJECTS: "activeProjects",
  COMPLETED_PROJECTS: "completedProjects",
  DELETED_PROJECTS: "deletedProjects",
  PAUSED_PROJECTS: "pausedProjects",
};

// useOrder
export const orderKeys = (id: string) => ["orders", id];

// useOrderMessages
export const orderMessagesKeys = (orderId: string) => [
  "orders",
  orderId,
  "messages",
];

// for custom hook useOfferAttachments
export const offerAttachmentsKey = (offerId: string) => [
  "attachments",
  "offer",
  offerId,
];

// useOrderDeliveries
export const orderDeliveryKeys = (id: string) => ["orders", id, "deliveries"];

// useOrderRatings
export const orderRatings = (orderId: string) => ["orders", orderId, "ratings"];

// useAttachmentByIds
export const attachmentKeys = (ids: string[]) => ["attachments", ids];

// useOrderRevisions
export const orderRevisions = (orderId: string) => [
  "orders",
  orderId,
  "revisions",
];

export const orderReceipt = (orderId: string) => ["orders", orderId, "receipt"];
// useOfferReviews
export const offerReviewsKeys = (offerId: string) => [
  "offers",
  offerId,
  "reviews",
];

// useOfferRequirements
export const offerRequirementsKeys = (offerId: string) => [
  "offer",
  offerId,
  "requirements",
];

// useOfferFaqs
export const offerFaqsKeys = (offerId: string) => ["offers", offerId, "faqs"];

// useCategories
export const categoriesKeys = () => ["categories"];

// useFetchComments
export const projectCommentsQueryKeys = (projectId: string) => [
  "projects",
  projectId,
  "comments",
];

// useOfferPrices
export const offerPricesQueryKeys = (offerId: string) => [
  "offers",
  offerId,
  "prices",
];

// useNewProjectPage
export const draftProjectsQueryKeys = () => ["projects", "draft"];

// useProfile
export const profileQueryKeys = (profileId: string) => ["profiles", profileId];

// useProfileOffer
export const profileOffersQueryKeys = (profileId: string) => [
  "profiles",
  profileId,
  "offers",
];

// useProfiles
export const profilesQueryKeys = (searchText?: string, category?: string) => [
  "profiles",
  searchText,
  category,
];

export const profileRatingQueryKeys = (profileId: string) => [
  "profiles",
  "ratings",
  profileId,
];

// useFetchProject
export const projectQueryKeys = (projectId: string) => ["projects", projectId];

// useOfferCategory
export const categoryQueryKeys = (categoryId: string) => [
  "categories",
  categoryId,
];

// useOfferRatings
export const offerRatingsQueryKeys = (offerId: string) => [
  "offers",
  offerId,
  "ratings",
];

// useOffers
export const offersQueryKeys = (searchText: string) => ["offers", searchText];

// useSubcategories
export const subcategoriesQueryKeys = (categoryId: string) => [
  "categories",
  categoryId,
  "subcategories",
];

// useOfferSubcategory
export const subcategoryQueryKeys = (subcategoryId: string) => [
  "subCategories",
  subcategoryId,
];

// useOfferTags
export const tagsQueryKeys = (ids: string | string[]) => ["tags", ids];

// useFetchProjects
export const projectsQueryKeys = (status?: string) =>
  status ? ["projects", status] : ["projects"];

// useAuth
export const authUserQueryKeys = () => ["authUser"];

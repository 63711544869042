import { VStack, HStack, Text, Box, Skeleton } from "@chakra-ui/react";
import useLanguage from "hooks/useLanguage";
import { FC } from "react";

const Language: FC<{ id: string }> = ({ id }) => {
  const { data: language, isLoading } = useLanguage(id);
  if (isLoading) return <Skeleton w="full" h="20px" />;
  if (!language) return;
  console.log({ language });
  return (
    <VStack align="start">
      <VStack spacing={0} align="start">
        <HStack key={language.name} gap={6} w="200px">
          <Text color="gray.500" fontSize="sm" fontWeight="semibold">
            {language.name}
          </Text>
          <HStack justifyContent="end">
            {Array.from({ length: 5 }, (_, i) => (
              <Box
                key={i}
                width="8px"
                height="8px"
                borderRadius="full"
                bg={
                  i < Number(language.proficiencyLevel.level)
                    ? "blue.500"
                    : "gray.300"
                }
              ></Box>
            ))}
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Language;

"use client";

import { useQuery } from "@tanstack/react-query";
import { getProfileRatingById } from "services/profilesService";
import { profileRatingQueryKeys } from "settings/constants/queryKeys";
import { Review } from "types/profile.types";

const useProfileRatings = (id: string) => {
  const profileQuery = useQuery<Review, Error>({
    queryKey: profileRatingQueryKeys(id),
    queryFn: () => getProfileRatingById(id),
    enabled: !!id,
  });

  return {
    ...profileQuery,
  };
};

export default useProfileRatings;
